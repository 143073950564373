import jquery from 'jquery'
import Http from './lib/Http'

require('select2')
const SELECT2SELECT = '.select2 select'

class Index {
  constructor () {
    this.bindSelectEvents()
  }

  bindSelectEvents () {
    jquery(window).resize(() => {
      this.select2Destroy()
      setTimeout(this.select2create, 10)
    })
    this.select2create()
  }

  select2Destroy () {
    jquery(SELECT2SELECT).select2('destroy')
    jquery('form').addClass('cloak')
    return true
  }

  select2create () {
    jquery(SELECT2SELECT).each(function () {
      jquery(this).select2({
        placeholder: jquery(this).attr('placeholder'),
      })
    })
    jquery(SELECT2SELECT).on('change', () => {
      this.updatePastEventsList()
    })

    jquery('form').removeClass('cloak')
  }

  updatePastEventsList () {
    // submit the form and update the view
    Http.ajaxFormSend(jquery('form[name=past-events-filter]'))
      .then((data) => {
        jquery('.content .past').html(data)
      })
      .catch((e) => {
        console.error(e)
      })
  }
}

jquery(document).ready(() => {
  new Index()
})
