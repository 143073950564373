import jquery from 'jquery'

class Http {
  request (method, path, data) {
    console.log(method)
    console.log(path)
    console.log(data)
    return new Promise((resolve, reject) => {
      jquery.ajax({
        url: path, // should be relative
        data: data,
        success: (result, status, xhr) => {
          resolve(result)
        },
      })
    })
  }
  ajaxFormSend($form) {
    let paramObj = {};
    jquery.each(jquery($form).serializeArray(), function(_, kv) {
      paramObj[kv.name] = kv.value;
    });
    return this.request(jquery($form).attr('method'), jquery($form).attr('action'), paramObj)
  }
}

export default new Http()
